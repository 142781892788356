import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    const animateImgs = (removeFrom, addTo) => {
        document
            .querySelectorAll('.' + removeFrom + '.animate')
            .forEach(img => img.classList.remove('animate'));

        document
            .querySelectorAll('.' + addTo)
            .forEach(img => img.classList.add('animate'));
    };

    return (
        <Layout navTheme="light">
            <Seo title="Co-Op" />
            <div className="coop-page">
                <Container className="purple-blob container-1 hero-container">
                    <img
                        className="right-star"
                        src={require('../../images/co-op/pinkstar.png')}
                        alt="Star"
                    />
                    <img
                        className="right-star2"
                        src={require('../../images/co-op/pinkstar2.png')}
                        alt="Star"
                    />
                    <img
                        className="left-star"
                        src={require('../../images/co-op/pink3.png')}
                        alt="Star"
                        />
                    <Row>
                        <Col className="centered-text">
                            <h1 className="white-text">
                                Introducing the Glasshive Co-Op Program
                            </h1>
                            <p className="hero-subtext yellow-text">
                                Let's Experience the                                 
                                <img
                                    className="magic"
                                    src={require('../../images/co-op/magic-pink.svg')}
                                    alt="Magic"
                                />
                                Together
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="features-container">
                    <Row className="marketing-and-sales-section">
                        <Col className="heading">
                            <h2 className="centered-heading">
                                MSP Marketing & Sales Made Possible
                            </h2>
                        </Col>
                    </Row>
                    <Row className="content-row">
                        <Col className="one-half">
                            <p>Are you struggling to harness the full power of GlassHive? Having trouble boosting your sales and msp marketing efforts? Feeling lost in a sea of data without seeing the outcomes you desire? Let us help you like a mighty sorcerer with their wand!</p>
                        </Col>
                        <Col className="one-half image-column">
                            <img
                                className="magic"
                                src={require('../../images/co-op/magic.png')}
                                alt="Magic"
                            />
                        </Col>
                    </Row>
                    <Row className="magic-elixer-section">
                        <Col className="heading">
                            <h2 className="centered-heading">
                                The Magic Elixir You’ll Receive
                            </h2>
                        </Col>
                    </Row>
                    <Row className="magic-elixer-content content-row">
                        <Col className="one-half image-column">
                            <img
                                className="flask"
                                src={require('../../images/co-op/flask.png')}
                                alt="Potion"
                            />
                        </Col>
                        <Col className="one-half">
                            <p>Get ready to transform your business operations with the magic of our brilliant Product Coaches. This quick start marketing program is designed to get your MSP marketing and selling without the hassle. Our Product Coaches are here to make things simple and fun.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="card-container">
                    <img
                        className="left-star"
                        src={require('../../images/co-op/4stars.png')}
                        alt="Star"
                    />
                    <img
                        className="right-star"
                        src={require('../../images/co-op/4stars.png')}
                        alt="Star"
                    />
                    <Row className="cards">
                        <Col className="card-container">
                            <div className="card">
                                <div class="header red">
                                    <h3>Set Up & Integrate Glasshive</h3>
                                    <img
                                        className="icon"
                                        src={require('../../images/co-op/integrate.svg')}
                                        alt="Integrate"
                                    />
                                </div>
                                <div class="body">
                                    <ul>
                                        <li>A Dedicated Product Coach.</li>
                                        <li>GlassHive License with up to 20 users!</li>
                                        <li>Customize it to match your brand's magic!</li>
                                        <li>Immediate access to our marketing materials library.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col className="card-container">
                            <div className="card">
                                <div class="header green">
                                    <h3>Execute Marketing Campaigns</h3>
                                    <img
                                        className="icon"
                                        src={require('../../images/co-op/execute.svg')}
                                        alt="Execute"
                                    />
                                </div>
                                <div class="body">
                                    <ul>
                                        <li>4 Email Campaigns a Month (2 w/ Graphics & 2 HTML Text)</li>
                                        <li>1 Designed Landing Page a Month</li>
                                        <li>2 Social Media Posts a Month</li>
                                        <li>Journey Automation Setup (Limit 1 per Quarter)</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="cards">
                        <Col className="card-container">
                            <div className="card">
                                <div class="header purple">
                                    <h3>Ongoing Training & Consulting</h3>
                                    <img
                                        className="icon"
                                        src={require('../../images/co-op/consulting.svg')}
                                        alt="Integrate"
                                    />
                                </div>
                                <div class="body">
                                    <ul>
                                        <li>Data interpretation for smart decision-making.</li>
                                        <li>Sales training that's akin to a mesmerizing magic apprenticeship</li>
                                        <li>Get the most out of  your Glasshive investment.</li>
                                        <li>We'll make  you a GlassHive wizard, wand not included.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col className="card-container">
                            <div className="card">
                                <div class="header pink">
                                    <h3>Proactive Reporting</h3>
                                    <img
                                        className="icon"
                                        src={require('../../images/co-op/reporting.svg')}
                                        alt="Execute"
                                    />
                                </div>
                                <div class="body">
                                    <ul>
                                        <li>Monthly insights delivered like your own letter from Hogwarts.</li>
                                        <li>All the analytics you need without the yawn.</li>
                                        <li>Instant access to the data you need.</li>
                                        <li>Set KPI's and witness them get met.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="buzz-container">
                    <Row className="marketing-and-sales-section">
                        <Col className="heading">
                            <h2 className="centered-heading pink-text">
                                Join the Hive, Feel the Buzz!
                            </h2>
                            <p>So, are you ready for a wild ride through the GlassHive universe? Step into our magical Co-Op program and let’s create some magic together!</p>
                        </Col>
                    </Row>
                    <img
                        className="left-star"
                        src={require('../../images/co-op/3star3.png')}
                        alt="Star"
                    />

                    <img
                        className="right-star"
                        src={require('../../images/co-op/3star4.png')}
                        alt="Star"
                    />
                </Container>

                <Container className="form-heading-container">
                    <Row className="marketing-and-sales-section">
                        <Col className="heading">
                            <h2 className="centered-heading white-text">
                                <span>Start today for </span>
                                <span class="img-container">                    
                                    <img
                                        className="right-star"
                                        src={require('../../images/co-op/3stars.png')}
                                        alt="Star"
                                    />
                                    <img
                                        className="left-star"
                                        src={require('../../images/co-op/3star2.png')}
                                        alt="Star"
                                    />
                                    <img
                                        className="price"
                                        src={require('../../images/co-op/price.png')}
                                        alt="Star"
                                    />
                                
                                </span><span>Month to Month!</span>
                            </h2>
                        </Col>

                    </Row>
                </Container>

                <Container className="form-container">
                    <Row className="form-section">
                        <Col className="form">
                            <AppForm
                                formName="coop-form"
                                submitBtnText="Sign up now!"
                                className="coop-form"
                                formCategory="Careers"
                            >
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    labelText="Name*"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    labelText="Email*"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="title"
                                    type="text"
                                    labelText="Title"
                                />
                                <AppInput
                                    fieldName="company"
                                    type="text"
                                    labelText="Company"
                                    requiredField
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
